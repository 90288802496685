import { Message } from "../../../../framework/src/Message";
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { message, Mentions } from "antd";
import { getStorageData, isEmpty, removeStorageData } from "../../../../framework/src/Utilities";
import axios from "axios";
const googleAPIkey = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
import { RouterProps } from "react-router";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import { createRef } from "react";
import { Redirect } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { cloneDeep } from "lodash";
import Mixpanel from "../../../../components/src/Mixpanel";
const { Option, getMentions } = Mentions;
export const configJSON = require("./../config");
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isCreatePostEnable: boolean;
  isMenuVisible: boolean;
  isBusinessUser: boolean;
  createPostDescription: any;
  postList: any[];
  isPostCreateLoading: boolean;
  videoArrayList: any;
  isloader: boolean;
  token: string | null;
  isCreatePostVisible: boolean;
  fullAddress: string;
  createPostImages: any;
  loading: boolean;
  likedItems: number[];
  followedItems: number[];
  userProfile: any;
  loginUser: any;
  accountDetails: any
  userAnalytics: any;
  isReportIssueVisible: boolean;
  reportPostID: any;
  selectedFile: any;
  value: any;
  mentionInputValue: string;
  GetConnectionArrayAcc: any;
  tagged_connection_ids: any;
  isEmojiPickerEnable: boolean;
  postComments: any;
  AllMedia: any
  knownPeople: any
  redirect: boolean;
  shiftRatings: any;
  drawerVisible: boolean;
  isModalInviteFriends: boolean;
  isModalNewsletter: boolean;
  reviews: any;
  ratingSends: any;
  ratingReceives: any;
  followersAndFollowingOpen: boolean;
  createPostOpen: boolean;
  keyValue: string;
  myReviews: any;
  defaultActiveKey: any;
  showAllComment: any;
  pledgeTracking: any
  followers: any;
  tagsOpen: boolean;
  editPostOpen: boolean,
  editData: any;
  newsubscribe: any;
  newsEmail :any;
  profileData: any;
  address: any;
  location: boolean;
  loadComments: any;
  editortext: any;
  open: boolean;
  suggestions: any;
  updatedDetails: any;
  commentslikeditems: any;
  data: any;
  generalBusinessRatingData: Object[];
  deleteExistingMedias: any;
  bodyText: boolean
  premiumModal: boolean;
  downloadResumeLoading: boolean;
  isNewsLetterSubscription: boolean | null;
  getMentionsArray: any;
  getMentionsArrayList: Array<string>;
  selectTags: any;
  perpageCountForPost: number;
  showLoadMore: boolean;
  showLoadMoreLoading: boolean;
  deleteId: any;
  updateQuestionnaire: any;
  isModel:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MyBusinessProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  labelLogOut: string;
  labelProfile: string;
  labelSetting: string;
  labelApplied: string;
  labelHome: string;
  labelMyNetwork: string;
  labelCommunity: string;
  labelChat: string;
  labelAt: string;
  labelCommunityForum: string;
  labelWorkAnniversaries: string;
  labelBirthAnniversaries: string;
  labelKnownPeople: string;
  labelInvitations: string;
  placeHolderSearchContact: string;
  placeHolderWhatOnMind: string;
  btnLearnMore: string;


  SearchUserDataGetId: any;
  PostID: any;
  getHomeFeedPostsApiCallId: any;
  getPendingInvitationsApiCallId: any;
  getKnownPeopleApiCallId: any;
  getNetworkConnectionAppliedId: any;
  addCommentInPost: any;
  getCommentInPostApiCallID: any;
  unsubscribe: any;
  getReviewsApiCallId: any;
  getRatingSendApiCallId: any;
  getRatingReceivedApiCallId: any;
  getMyReviewsApiCallId: any;
  GetProfielInformationId: any;

  getConnectionApiCallId: any;
  deletePostApiCallId: any;
  addReplyCommentInPost: any;
  geteditFeedPostApiCallId: any;
  editPostID: any;
  postNewsSubscribeCallID: any;
  postNewsUnSubscribeCallID: any;
  getAccountDataEndPoint: any
  getUserAnalyticsApiCallId : any ;
  // Customizable Area End
  contentRef = createRef<any>();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      updateQuestionnaire: {},
      deleteId: '',
      perpageCountForPost: 1,
      showLoadMore: false,
      showLoadMoreLoading: false,
      pledgeTracking: {},
      isCreatePostEnable: false,
      isMenuVisible: false,
      isBusinessUser: false,
      createPostDescription: EditorState.createEmpty(),
      postList: [],
      isPostCreateLoading: false,
      videoArrayList: [],
      isloader: false,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      isCreatePostVisible: false,
      fullAddress: "",
      createPostImages: [],
      loading: false,
      likedItems: [],
      followedItems: [],
      loginUser: {},
      userProfile: {},
      isReportIssueVisible: false,
      reportPostID: "",
      selectedFile: null,
      value: 1,
      GetConnectionArrayAcc: [],
      mentionInputValue: "",
      tagged_connection_ids: [],
      isEmojiPickerEnable: false,
      postComments: [],
      AllMedia: [],
      accountDetails: {},
      userAnalytics : undefined,
      knownPeople: [],
      redirect: false,
      shiftRatings: 1,
      drawerVisible: false,
      isModalInviteFriends: false,
      isModalNewsletter: false,
      reviews: [],
      myReviews: [],
      ratingSends: {},
      ratingReceives: [],
      followersAndFollowingOpen: false,
      createPostOpen: false,
      keyValue: "",
      defaultActiveKey: 1,
      showAllComment: false,
      followers: [],
      tagsOpen: false,
      editData: {},
      editPostOpen: false,
      newsubscribe: false,
      newsEmail : "",
      profileData: [],
      address: null,
      location: false,
      loadComments: false,
      editortext: "",
      open: false,
      suggestions: [],
      updatedDetails: {},
      commentslikeditems: [],
      data: {},
      generalBusinessRatingData: [],
      deleteExistingMedias: [],
      bodyText: false,
      premiumModal: false,
      downloadResumeLoading: false,
      isNewsLetterSubscription: null,
      getMentionsArray: [],
      getMentionsArrayList: [],
      selectTags: [],
      isModel:false
    };
    this.btnLearnMore = configJSON.btnLearnMore;
    this.placeHolderWhatOnMind = configJSON.placeHolderWhatOnMind;
    this.placeHolderSearchContact = configJSON.placeHolderSearchContact;
    this.labelLogOut = configJSON.labelLogOut;
    this.labelProfile = configJSON.labelProfile;
    this.labelSetting = configJSON.labelSetting;
    this.labelApplied = configJSON.labelApplied;
    this.labelHome = configJSON.labelApplied;
    this.labelMyNetwork = configJSON.labelMyNetwork;
    this.labelCommunity = configJSON.labelCommunity;
    this.labelChat = configJSON.labelChat;
    this.labelAt = configJSON.labelAt;
    this.labelCommunityForum = configJSON.labelCommunityForum;
    this.labelWorkAnniversaries = configJSON.labelWorkAnniversaries;
    this.labelBirthAnniversaries = configJSON.labelBirthAnniversaries;
    this.labelKnownPeople = configJSON.labelKnownPeople;
    this.labelInvitations = configJSON.labelInvitations;

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentWillMount() {
    await this.GetProfileInformation();
  }
  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getHomeFeedPosts();
    this.handleInvitations();
    this.getKnownPeople();
    let data: any = await getStorageData("loginUser").then((res) => {
      if (res) {
        this.setState({
          loginUser: res,
          isBusinessUser: res.role === "business",
        });
      }
    });
    this.geProfileAccount()
    this.getReviews();
    await getStorageData("userProfile").then((res) => {
      const response = res?.attributes?.profile?.data?.attributes;

      this.setState({ userProfile: response });
    });
    const rest = await getStorageData("accountData")
    if (window.location.pathname === "/MyBusiness/Profile") {

      window.history.replaceState(
        null,
        "",
        `${window.location.pathname}?${rest?.attributes.account.data.id}/${rest.attributes.account.data.attributes?.full_name.split(" ").join("_")}`
      );

    }
    this.setState({ accountDetails: rest });

    await getStorageData('updatedProfile').then((res) => {

      if (res) {
        this.setState({ updatedDetails: res });
      }
    });
    this.getNetworkConnectionApplied();
    this.getRatingSend();
    this.getRatingReceived();
    if(this.state?.accountDetails?.attributes?.account?.data?.attributes?.account_plan === "premium"){
      this.getUserAnalytics()
    }
    this.getConnections();
  }
  getUserAnalytics = () => {
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: localStorage.getItem("token")
    };
    this.getUserAnalyticsApiCallId = requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.getUserAnalyticsApiCallId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.GETMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),header
    );
    
    runEngine.sendMessage(requestMessage.id, 
      requestMessage);
  }
  geProfileAccount = () => {
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountDataEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAccountDetailsEndPoint}/${this.state.loginUser?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAccountDetailsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async GetProfileInformation() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetProfielInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetProfileInfoEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SearchAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  handleFollowersAndFollowingCancel = () => {
    this.setState({ followersAndFollowingOpen: false })
  }

  handlecreatePostOpenCancel = () => {
    this.setState({
      bodyText: false,
      createPostDescription: EditorState.createEmpty(),
      getMentionsArray: [],
      selectTags: [],
      address: null,
      createPostOpen: false,
      location: false,
      AllMedia: [],
      getMentionsArrayList: [],
      createPostImages: [],
      videoArrayList: [],
    })
  }
  handlecreatePostOpen = () => {
    this.setState({
      getMentionsArray: [],
      selectTags: [],
      address: null,
      createPostDescription: EditorState.createEmpty(),
      createPostOpen: true,
      location: false,
      tagsOpen: false,
      AllMedia: [],
      getMentionsArrayList: [],
      createPostImages: [],
      videoArrayList: [],
    })
  }
  handleEditPostOpen = (id: any) => {
    this.setState({
      editPostOpen: true,
      createPostOpen: false,
      createPostDescription: EditorState.createEmpty(),
      getMentionsArray: [],
      selectTags: [],
      address: null,
      location: false,
      getMentionsArrayList: []
    })
    this.getFeedPost(id);

  }
  handleFollowersAndFollowingOpen = (data: string) => {
    if (data.match(/followers/gi)) {
      this.setState({ followersAndFollowingOpen: true, keyValue: "1" })
    } else if (data.match(/Following/gi)) {
      this.setState({ followersAndFollowingOpen: true, keyValue: "2" })
    }
  }

  handleEditPostCancel = () => {
    this.setState({
      editPostOpen: false,
      AllMedia: [], createPostImages: [], videoArrayList: [], isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
      // postList: responseJson.data,
      address: null,
      getMentionsArray: [],
      selectTags: [],
      location: false,
      tagsOpen: false,
      createPostDescription: EditorState.createEmpty(),
      isloader: false,
    })
  }
  handleDataUpdateAfterEditorDelete = async (post: any, str?: any) => {
    await this.GetProfileInformation()
    let list = this.state.postList.map((item: any) => {
      if (item?.attributes?.id != post?.attributes?.id) {

        return item
      } else {

        if (str === 'delete') {
          return
        } else {
          return post
        }
      }
    })
    this.setState({
      postList: list.filter((item: any) => item !== undefined)
    })

  }

  handleGetHomeFeedPosts = (responseJson: any) => {
    if (responseJson.data?.length < 3) {
      this.setState({
        showLoadMore: false
      })
    } else {
      this.setState({
        showLoadMore: true
      })
    }
    this.setState({
      deleteExistingMedias: [],
      showLoadMoreLoading: false,
      postList: [...this.state.postList, ...responseJson.data],
      // likedItems:,
      isloader: false,
      AllMedia: [], createPostImages: [], videoArrayList: [], createPostDescription: EditorState.createEmpty(), isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
    });
    if (responseJson?.data) {
      let value = responseJson.data.map((c: any) => c.attributes.liked && this.setState((prevState) => ({
        likedItems: [...prevState.likedItems, c.id],
      })));
      responseJson?.data.map((ele: any) => console.log(ele))
      let valueFollowed = responseJson.data.map((c: any) => c.attributes.account.data.attributes.following && this.setState((prevState) => ({
        followedItems: [...prevState.followedItems, c?.attributes?.account?.data?.id],
      })));
    }
  }
  handleInvalidtoken = async (responseJson: { errors: { token: any; }[]; }) => {
    if (responseJson?.errors[0]?.token) {
      this.props.history.push({
        pathname: "/"
      });
      localStorage.removeItem("token");
      await removeStorageData("loginUser");
      await removeStorageData("userProfile");
      await removeStorageData("accountData");
      await removeStorageData("signUpUser");
      await removeStorageData("signUpUserResponse");
      await removeStorageData("verificationData");
      await removeStorageData("signUpUserID");
      await removeStorageData("isFromLogin");
      await removeStorageData("updatedProfile");
      await removeStorageData("userProfile-1");
    }
  }

  handleNetworkConnectionApplied = (responseJson: any) => {
    let receivedData = responseJson.data;
    let finalFriendsData: any = [];
    receivedData &&
      receivedData.forEach((item: any, index: any) => {
        if (item && item.attributes && item.attributes.full_name) {
          let finalItem = item;
          finalItem["name"] = item.attributes.full_name;
          finalFriendsData.push(finalItem);
        }
      });
    this.setState(
      {
        GetConnectionArrayAcc: finalFriendsData,
      },
      () => {
        if (
          this.state.GetConnectionArrayAcc &&
          this.state.GetConnectionArrayAcc.length > 0
        ) {
          const connectedFriends = this.state.GetConnectionArrayAcc.map(
            (item: any) => item.attributes.connected_as
          )
            .filter((v: any, i: any, a: any) => a.indexOf(v) == i)
            .filter((x: any) => x !== null)
            .map((x: any) => {
              return { value: x };
            });
        }
      }
    );
  }
  handleProfielInformation = (responseJson: any) => {
    const res = responseJson?.data?.attributes?.profile?.data?.attributes;
    if (responseJson.data) {
      this.setState({
        pledgeTracking: responseJson.data?.attributes?.profile?.data?.attributes?.pledge_tracking,
        profileData: responseJson?.data,
        userProfile: res
      })
    }
  }
  handleEditPost = (responseJson: any) => {
    if (responseJson.data) {
      this.handleDataUpdateAfterEditorDelete(responseJson?.data)
      this.setState({
        AllMedia: [], createPostImages: [], videoArrayList: [], isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
        // postList: responseJson.data,
        tagsOpen: false,
        location: false,
        address: null,
        createPostDescription: EditorState.createEmpty(),
        isloader: false,
      });
    }
  }
  handleAccountData = (responseJson: any) => {
    if (responseJson?.data) {
      const accountData = responseJson?.data?.attributes?.account?.data?.attributes;
      this.setState({
        data: accountData,
        generalBusinessRatingData: this.getAppliedOrder(accountData.rating?.data?.attributes?.rating['Applied View Rating']?[...accountData.rating?.data?.attributes?.rating['Applied View Rating']]:[]),
        isNewsLetterSubscription: responseJson?.data?.attributes?.newsletter_subscription === null ? false : responseJson?.data?.attributes?.newsletter_subscription,
      })
    }
  }
  handleCommentInPost = (responseJson: any) => {
    let list = this.state.postList.map((value) =>
      value.id == responseJson.data[0]?.attributes?.commentable_id
        ? { ...value, attributes: { ...value.attributes, comments: { data: responseJson.data } } }
        : value
    );

    this.setState({
      showAllComment: true,
      loadComments: false,
      postComments: responseJson.data,
      postList: list
    });
  }
  handleApiCall1 = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getHomeFeedPostsApiCallId) {
      this.handleGetHomeFeedPosts(responseJson)
    } else if (apiRequestCallId === this.GetProfielInformationId) {

      this.handleProfielInformation(responseJson)

    } else if (apiRequestCallId === this.PostID) {

      this.setState({
        deleteExistingMedias: [],
        AllMedia: [], createPostImages: [], videoArrayList: [], isCreatePostEnable: false, isCreatePostVisible: false, tagged_connection_ids: [],
        tagsOpen: false,
        location: false,
        address: null,
        getMentionsArray: [], selectTags: [],
        createPostDescription: EditorState.createEmpty(),
        isloader: false,
      });
      await this.GetProfileInformation()
      const { postList } = this.state;
      this.setState({
        postList: [responseJson?.data, ...postList], 
        isPostCreateLoading: false
      });
      
    } else if (apiRequestCallId === this.editPostID) {
      this.handleEditPost(responseJson)
    } else if (apiRequestCallId === this.addCommentInPost) {
      this.setState({
        isloader: false,
        loadComments: false
      });
      this.getComments(responseJson.data.attributes.commentable_id);
      this.getHomeFeedPosts();
    } else if (apiRequestCallId === this.addReplyCommentInPost) {
      this.setState({
        isloader: false,
        loadComments: false
      });
    } else if (apiRequestCallId === this.getAccountDataEndPoint) {
      this.handleAccountData(responseJson);
    } else if (apiRequestCallId === this.getCommentInPostApiCallID) {

      this.handleCommentInPost(responseJson)
    } else if (apiRequestCallId === this.getPendingInvitationsApiCallId) {

      this.setState({
        isloader: false,
      });
    } else if (apiRequestCallId === this.getKnownPeopleApiCallId) {

      this.setState({
        knownPeople: responseJson.data,
        isloader: false,
      });
    } else if (apiRequestCallId === this.getNetworkConnectionAppliedId) {
      this.handleNetworkConnectionApplied(responseJson)
    } else if (apiRequestCallId === this.getUserAnalyticsApiCallId){
      this.setState({
        userAnalytics : responseJson
      })
    }
  }
  handleApiCall2 = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getMyReviewsApiCallId) {
      this.setState({ myReviews: responseJson.data });
    } else if (apiRequestCallId === this.getConnectionApiCallId) {
      this.setState({ followers: responseJson.data });
    } else if (apiRequestCallId === this.deletePostApiCallId) {
      const data = { attributes: { id: this.state.deleteId } }
      this.handleDataUpdateAfterEditorDelete(data, 'delete')
    } else if (apiRequestCallId === this.postNewsSubscribeCallID) {
      this.setState({ newsubscribe: true });

    } else if (apiRequestCallId === this.postNewsUnSubscribeCallID) {
      this.setState({
        newsubscribe: false,
        isNewsLetterSubscription: false
      });

    } else if (apiRequestCallId === this.geteditFeedPostApiCallId) {
      let tags: any = []
      responseJson.data?.attributes?.taggings?.data.map((item: any) => {
        const name = item?.attributes?.role === "business" ? `@${item?.attributes?.profile?.data?.attributes?.company_name}` :
          `@${item?.attributes?.full_name}`
        tags = [...tags, ...getMentions(name)]
      })
      this.setState({
        getMentionsArray: tags,
        editData: responseJson.data, isloader: false,
        address: responseJson.data?.attributes?.location
      })
      this.setState({
        createPostDescription:
          EditorState.createWithContent(ContentState.createFromText(responseJson.data?.attributes?.body))
      });
      this.setState({ tagged_connection_ids: [] });
      this.setState({ AllMedia: responseJson.data?.attributes?.media });
    } else if (apiRequestCallId === this.getRatingReceivedApiCallId) {
      this.setState({ ratingReceives: responseJson.data });

    } else if (apiRequestCallId === this.getRatingSendApiCallId) {
      this.setState({ ratingSends: responseJson.data });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.errors) {
        this.handleInvalidtoken(responseJson)
      }

      if (apiRequestCallId && responseJson) {
        this.handleApiCall1(apiRequestCallId, responseJson)
      } else {
        this.parseApiErrorResponse(responseJson);
      }
      if (apiRequestCallId && responseJson) {
        this.handleApiCall2(apiRequestCallId, responseJson)
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
    }
  }

  // Customizable Area Start

  getAppliedOrder = (generalBusinessRatingData: Object[]) => {
    const appliedOrder = ['A', 'P', 'P', 'L', 'I', 'E', 'D'];
    const compareNamesOrder = (rating1: any, rating2: any) => {
      const nameRating1 = (rating1.name || '').toUpperCase();
      const nameRating2 = (rating2.name || '').toUpperCase();
      if (!nameRating1 && !nameRating2) {
        return 0;
      } else if (!nameRating1) {
        return 1;
      } else if (!nameRating2) {
        return -1;
      }
      const indexRating1 = appliedOrder.indexOf(nameRating1[0]);
      const indexRating2 = appliedOrder.indexOf(nameRating2[0]);
      if (indexRating1 < indexRating2) {
        return -1;
      } else if (indexRating1 > indexRating2) {
        return 1;
      } else {
        if (nameRating1 < nameRating2) {
          return -1;
        } else if (nameRating1 > nameRating2) {
          return 1;
        } else {
          return 0;
        }
      }
    };
    generalBusinessRatingData.sort(compareNamesOrder);
    return generalBusinessRatingData;
  }

  getDomainSkills(userDataHere: any) {
    if (
      isEmpty(userDataHere) ||
      isEmpty(userDataHere.attributes) ||
      isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere.attributes.domain_skills;
    const domainSkillsStr = domainSkillsPresent.join(", ");

    return domainSkillsStr;
  }

  getHomeFeedPosts = async () => {
    this.setState({ isloader: true });
    this.setState({
      showLoadMoreLoading: true
    })
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeFeedPostsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLoginUserPostApiEndPoint + `?per_page=3&page=${this.state.perpageCountForPost}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // getUserLocattion = async () => {
  //   await navigator.geolocation.getCurrentPosition(
  //     async (position) => {
  //       let googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude
  //         },${position.coords.longitude}&key=${googleAPIkey}`;
  //       let cityName = "",
  //         stateName = "",
  //         country = "",
  //         postalCode = "";
  //       let locationResult: any = await axios.get(googleMapApi);
  //       locationResult.data.results[0].address_components.forEach((rs: any) => {
  //         if (rs.types.includes("locality")) {
  //           cityName = rs.long_name;
  //         }

  //         if (rs.types.includes("administrative_area_level_1")) {
  //           stateName = rs.long_name;
  //         }

  //         if (rs.types.includes("country")) {
  //           country = rs.long_name;
  //         }

  //         if (rs.types.includes("postal_code")) {
  //           postalCode = rs.long_name;
  //         }
  //       });

  //       const fullAddress = locationResult.data.results[0].formatted_address;
  //       // console.log("fullAddress", JSON.stringify(fullAddress));
  //       this.setState({
  //         fullAddress: JSON.stringify(fullAddress),
  //       });
  //     },
  //     (err) => console.log(err)
  //   );
  // };
  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  beforeUpload(file: any) {
    const isJpgOrPng =
      file.type.slice(0, 5) == "image" ||
      file.type.slice(0, 5) == "video";
    let isLt2M = false;
    if (file.type.slice(0, 5) == "image") {
      isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Image must smaller than 5MB!');
      }
    } else if (file.type.slice(0, 5) == "video") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Video must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.createPostImages];
      let VideoArray = [...this.state.videoArrayList];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 5) == "video" || file.type.slice(0, 5) == "image")) {
        AllMedia.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      if (file.type.slice(0, 5) == "video" && isLt2M) {
        VideoArray.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else if (file.type.slice(0, 5) == "image" && isLt2M) {
        ImageArrayList.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else {
        message.error("invalid file type!!!");
      }
      this.setState({
        createPostImages: ImageArrayList,
        videoArrayList: VideoArray,
        AllMedia,
        loading: false,
      });
    });
    return true;
  }

  handleImageChange = (info: any) => {

    // if (info.file.status === "uploading") {
    //   this.setState({ loading: true });
    //   message.error("uploading");
    //   return;
    // }
    // if (info.file.status === "done") {

    // } else if (info.file.status === "error") {
    //   console.log("FILE INFO", info, info.file);
    // message.error(`${info.file.name} file upload failed.`);
    // } else {
    // message.error("failed");
    // }
  };

  deleteItem = (index: any) => {
    let deleteExistingMedias: any = cloneDeep(this.state.deleteExistingMedias);
    this.state.AllMedia.forEach((item: any, indexHere: any) => {
      if (index === indexHere && item.blob_id) {
        deleteExistingMedias.push(item);
      }
    });


    let clonedArr = [...this.state.AllMedia].filter(
      (el: any, i: number) => index !== i
    );
    this.setState({ AllMedia: clonedArr, deleteExistingMedias });
  };

  onRemoveImage = (index: number) => {
    let imageData = this.state.createPostImages.filter((item: any, id: number) => {
      return id !== index;
    })
    let videoData = this.state.videoArrayList.filter((item: any, id: number) => {
      return id !== index;
    })
    let data = this.state.AllMedia;
    data.splice(index, 1);
    this.setState({
      AllMedia: data,
      createPostImages: imageData,
      videoArrayList: videoData
    });
  };

  //NewsletterSubscribe
  NewsletterSubscribe = async (value: String) => {

    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        email: value
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewsSubscribeCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postNewsSubscribe}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postNewsSubscribeMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  NewsletterUnSubscribe = async () => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewsUnSubscribeCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postNewsUnSubscribe}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postNewsUnSubscribeMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // edit post function
  handleEditSubmit = async (id: any) => {
    var ii: any = this.state.getMentionsArray;
    var jj: any = this.state.selectTags;
    var tags = [];
    for (var i = 0; i < ii.length; i++) {
      for (var j = 0; j < jj.length; j++) {
        let a = ii[i].value;
        let b = jj[j].value;
        let c = b.substring(0, b?.split(" ")[0].length)
        if (a == c) {
          tags.push(jj[j].key)
        }
      }
    }
    let t = this.state.tagged_connection_ids;
    t.push(...tags);
    this.setState({ tagged_connection_ids: t });
    this.setState({ editPostOpen: false, getMentionsArray: [], selectTags: [], })
    const header = {
      // "Content-Type": configJSON.createPostApiContentType,
      // "Content-Type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
      token: this.state.token,
    };

    let data = {
      status: "pending",
    };

    let trimmedStr = this.state.editortext.trim();

    var form = new FormData();


    await form.append("body", trimmedStr.toString());

    // formdata.append("name", 'ABC');

    if (this.state.address && this.state.address !== "") {
      await form.append("location", this.state.address);
    }
    if (this.state.tagged_connection_ids.length > 0) {
      const uniqueTaggedIds = Array.from(
        new Set(this.state.tagged_connection_ids)
      );
      uniqueTaggedIds.forEach((taggedId: any) => {
        form.append("taggings_attributes[]account_id", taggedId);
      });
    }
    if (this.state.AllMedia.length > 0) {
      this.state.AllMedia.forEach((item: any) => {
        if (item.hasOwnProperty("blob_id")) delete item.blob_id;
        if (item.data) form.append("media[]", item.data);
        if (item.content_type && item.uri) form.append("media[]", item);
      });
    }

    if (this.state.deleteExistingMedias.length > 0) {
      this.state.deleteExistingMedias.forEach((item: any) => {
        if (item.blob_id) form.append("blob_ids[]", item.blob_id);
      });
    }

    // const httpBody = {
    //   data: data,
    // };
    var options = { content: form };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editPostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postendpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
      // {"_parts": [["body", "Parth"]]}
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUTMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    // return true;
  };

  handleSubmit = async () => {
    this.setState({isPostCreateLoading: true})
    let accountData = await getStorageData("accountData", false)
    Mixpanel.track("Post Through Business Account", { "Post": accountData?.attributes?.account?.data?.attributes?.email })
    var ii: any = this.state.getMentionsArray;
    var jj: any = this.state.selectTags;
    var tags = [];
    for (var i = 0; i < ii.length; i++) {
      for (var j = 0; j < jj.length; j++) {
        let a = ii[i].value;
        let b = jj[j].value;
        let c = b.substring(0, b?.split(" ")[0].length)
        if (a == c) {
          tags.push(jj[j].key)
        }
      }
    }
    let t = this.state.tagged_connection_ids;
    t.push(...tags);
    this.setState({ tagged_connection_ids: t });

    const header = {
      token: this.state.token,
    };

    let data = {
      status: "pending",
    };

    let trimmedStr = this.state.editortext.trim();

    var form = new FormData();

    if (trimmedStr.length > 0) {
      // this.handlecreatePostOpenCancel();
      await form.append("body", trimmedStr.toString());

      if (this.state.address && this.state.address !== "") {
        await form.append("location", this.state.address);
      }

      if (this.state.tagged_connection_ids) {
        const uniqueTaggedIds = Array.from(
          new Set(this.state.tagged_connection_ids)
        );
        uniqueTaggedIds.forEach((taggedId: any) => {
          form.append("taggings_attributes[]account_id", taggedId);
        });
      }

      if (this.state.createPostImages.length > 0) {
        this.state.createPostImages.forEach((item: any) => {
          form.append("media[]", item.data);
        });
      }
      if (this.state.videoArrayList.length > 0) {
        this.state.videoArrayList.forEach((item: any) => {
          form.append("media[]", item.data);
        });
      }

      var options = { content: form };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.PostID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postendpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        form
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.handlecreatePostOpenCancel();
      return true;
    } else {
      this.setState({
        bodyText: true
      })
    }
  };
  likePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dislikePost = async (id: number) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      this.setState({
        likedItems: this.state.likedItems.filter(function (el) {
          return el !== id;
        }),
      });
      this.dislikePost(id);
    } else {
      this.setState((prevState) => ({
        likedItems: [...prevState.likedItems, id],
      }));
      this.likePost(id);
    }
  };

  toggleCommentLike = (id: number, liked: boolean, itemId?: any) => {
    if (liked) {
      this.dislikedComment(id, itemId);
    } else {
      this.likedComment(id, itemId);
    }
  };

  onClickFollow = async (id: number, isFollowed: boolean) => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_follow/follows?account_id=${id}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (isFollowed) {
        this.setState({
          followedItems: this.state.followedItems.filter(function (el) {
            return el !== id;
          }),
        });
      } else {
        this.setState((prevState) => ({
          followedItems: [...prevState.followedItems, id],
        }));
      }
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  addComment = (id: number, comment: any) => {
    this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
          // "taggings_attributes": [
          //     {
          //         "account_id": 2
          //     }
          // ]
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  addReplyComment = (id: number, comment: any) => {
    this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    let body = {
      data: {
        attributes: {
          comment_id: id,
          comment: comment,
        },
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReplyCommentInPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getComments = (id: number) => {
    this.setState({ loadComments: true });
    const token = this.state.token;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommentInPostApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}?post_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  likedComment = async (id: number, itemId?: any) => {
    this.setState({ isloader: true });
    const token = await this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/like`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    this.getComments(itemId);
    return true;
  };
  dislikedComment = (id: number, itemId?: any) => {
    this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      // "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createComment}/${id}/dislike`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.likeDislikeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isloader: false });
    this.getComments(itemId);
    return true;
  };
  handleInvitations = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const json_data = {
      status: "pending",
      page: "1",
      per_page: "10",
    };

    const httpBody = json_data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingInvitationsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNetworkEndPoint + "?status=pending"
      // configJSON.getNetworkEndPoint+ "?status=pending"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      // JSON.stringify(header)
      header
    );
    // requestMessage.addData(
    //     getName(MessageEnum.RestAPIRequestBodyMessage),
    //     // JSON.stringify(httpBody)
    //     json_data
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getKnownPeople = () => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getKnownPeopleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKnownPeopleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMenuClick(e: any, id: any) {

    if (e.key === "save") {
      // setDropDownVisible(true);
      this.savePost(id, "save");
    }
    if (e.key === "hide") {
      this.savePost(id, "hide");
    }
    if (e.key === "report") {
      // setDropDownVisible(true);
      this.setState({ isReportIssueVisible: true, reportPostID: id });
    }
  }

  handlePostList = (data: any, status: string) => {
    const postData = this.state.postList;
    if (status === "update") {

      const updatePostList = postData?.map((item: any) => {
        if (item.id === data.id) {
          return data
        } else {
          return item
        }
      })
      this.setState({ postList: updatePostList });
    } else if (status === "delete") {
      const updatePostList = postData?.filter((item: any) => {
        return item.id != data?.id
      })
      this.setState({ postList: updatePostList });
    }
  }

  handleOk = async (e: any) => {

    await this.savePost(this.state.reportPostID, "report");
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
    // setDropDownVisible(false);
  };

  handleCancel = (e: any) => {
    this.setState({ isReportIssueVisible: false, reportPostID: "" });
  };

  onChangeValue = (e: any) => {
    this.setState({ value: e.target.value });
  };

  savePost = async (id: number, endString: string) => {
    // this.setState({ isloader: true });
    const token = this.state.token;
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPostsApiEndpoint}/${id}/${endString}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getNetworkConnectionApplied = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      // token: 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzUxLCJleHAiOjE2MjIyODE3OTV9.VeU0sy4U9YiEJXyATrMzJWwFPlgaSm9KqwQVwoX7ZmLJ8SXW7oQQY3NgQ5oolFrKyhron8Bha9RZ0XCP7QNPnQ',
    };
    //   let formdata: any = new FormData();
    //   formdata.append("[token]", 'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTI4LCJleHAiOjE2MjIxMjU0MDd9.GxLPNGdjH_WraIR5jIR4eURIPVsXogq2VKcYebGSvTfC407qLv2PKtDW6c9IuFXneT0lKsV_POuSQJepNErovA');
    let data = {
      status: "pending",
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNetworkConnectionAppliedId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNetworkEndPoint + "?status=accepted"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    //   requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNetworkApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  ratingOnchange = (e: any) => {

    this.setState({
      shiftRatings: e.target.value,
    });
  };
  // get reviews from another one
  getReviews = async () => {
    let id = this.state.loginUser?.id
    this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyReviewsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyReviewsApiEndpoint + "?account_id=" + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // get ratingReceived
  getRatingReceived = async () => {
    let id = this.state.userProfile?.account_id
    this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRatingReceivedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRatingReceivedApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // get ratingsend
  getRatingSend = async () => {
    let id = this.state.userProfile?.account_id
    this.setState({ isloader: true });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRatingSendApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRatingSendApiEndpoint + '?account_id=' + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // get connections
  getConnections = async () => {
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getConnectionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getConnectionApiCallIdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // edit api
  getFeedPost = async (id: number) => {
    this.setState({ isloader: true });
    this.setState({
      AllMedia: [], createPostImages: [], videoArrayList: [],
      createPostDescription: EditorState.createEmpty(),
      isCreatePostEnable: false,
      isCreatePostVisible: false, tagged_connection_ids: [],
      // isloadEditPost: true,
      deleteExistingMedias: []
    });
    // const token = await getStorageData("token");
    // token= localStorage.getItem("token") ? localStorage.getItem("token"):"",
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.geteditFeedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.getUserPostsApiEndpoint
      `${configJSON.getPostsApiEndpoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPostsApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goToUpgrade = () => {
    if (this.state.data?.account_plan == "premium") {
      this.props.history.push({ pathname: '/UpgradeAccount' })
    } else {
      this.props.history.push({ pathname: '/Premium/Account' })
    }
  }

  // delete post 
  deletePost = async (id: any) => {
    this.setState({
      deleteId: id
    })
    const header = {
      "Content-Type": configJSON.getPostsApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETEMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  download(dataurl: any, filename: any) {
    var link = document.createElement('a');
    link.href = dataurl;

    var fileName = filename;
    link.download = fileName;
    link.click();
    this.setState({
      downloadResumeLoading: false
    })
  }

  beforeUploadAttachment(file: any) {
    let isLt2M = false;

   if (file.type.slice(0, 11) == "application"){
     isLt2M = file.size / 1024 / 1024 < 10;
     if (!isLt2M) {
       message.error('Pdf/Doc must smaller than 10MB!');
     }
   }

   this.getBase64(file, (imageUrl: string) => {
     let ImageArrayList = [...this.state.createPostImages];
     let AllMedia = [...this.state.AllMedia]
     if (isLt2M && (file.type.slice(0, 11) == "application")) {
       AllMedia.push({
         filePath: imageUrl,
         uri: imageUrl,
         type: file.type,
         name: file.name,
         data: file,
       });
       ImageArrayList.push({
         filePath: imageUrl,
         uri: imageUrl,
         type: file.type,
         name: file.name,
         data: file,
       });
     }
      else {
       message.error("invalid file type!!!");
     }

     this.setState({
       createPostImages: ImageArrayList,
       AllMedia,
       loading: false,
     });
   });

   return true;
 }

  // Customizable Area End
}
